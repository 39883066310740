<template>
  <div>
    <div class="flex-justify">
      <v-title title="Referral Source"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="addRefer"
        >
          Add Referral
        </v-button>
      </div>
    </div>
    <el-row
      class="row-bg"
      :gutter="20"
      justify="space-around">
      <v-row-col>
        <div class="form-item ">
          <span class="mg-b-4 block">Agency Code</span>
          <v-input
            v-model="formData.name"
            disabled/>
        </div>
      </v-row-col>
      <v-row-col>
        <div class="form-item">
          <span class="mg-b-4 block">Contact Phone Number</span>
          <v-input
            v-model="formData.mobile_no"
            disabled/>
        </div>
      </v-row-col>
    </el-row>
    <refer-dialog
      ref="referDialog"
      @addReferr="addReferr"></refer-dialog>
  </div>
</template>

<script>
import referDialog from '@/views/components/motor/dialog/referDialog'
import {mapState} from 'vuex'
export default {
  name: 'referral',
  components:{
    referDialog
  },
  props: {
    form: {
      type: [Object,Array],
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    }
  },
  data(){
    return{
      formData:{
        referral_id:'',
        name:'',
        mobile_no:''
      }
    }
  },
  computed:{
    ...mapState('quotation', ['quoteInformationForm']),
    isShowAddButton(){
      let {policy_type = 200} = this.quoteInformationForm||{}
      return policy_type!==203
    }

  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
          }
        })
        let { id } = val
        this.formData.referral_id = id||''
      }
    },
  },
  methods:{
    addRefer(){
      this.$refs.referDialog.showReferDialog()
    },
    handleDelRefer(){
      this.formData = {
        referral_id:'',
        name:'',
        mobile_no:''
      }
      this.$emit('getFormData','referral',this.formData)
    },
    addReferr(data){
      this.formData = data
      this.$emit('getFormData','referral',this.formData)
    },
    submitForm(formName){
      this.$emit('getFormData','referral',this.formData)
    },
  }
}
</script>

<style scoped>

</style>
