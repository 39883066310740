import axios from '@/jslib/axios'

export const saveQuotationMotor = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/motors',
    data
  })
}
export const saveQuotationRisks = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/risks',
    data
  })
}
export const saveQuotationTravel = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/travel',
    data
  })
}

export const saveQuotationHome = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/home',
    data
  })
}
export const saveQuotationEmployee = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/employee/compensation',
    data
  })
}
export const saveQuotationBusiness = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/business/package',
    data
  })
}
export const saveQuotationDomestic = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/domestic/helper',
    data
  })
}
export const saveQuotationConstruction = data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/construction/employee/compensation',
    data
  })
}
export const saveQuotationOthers= data => {
  return axios({
    method: 'post',
    url: '/admin/quotation/others',
    data
  })
}
export const getQuotationStatus= params => {
  return axios({
    method: 'get',
    url: '/admin/quotation/pdf/status',
    params
  })
}
